import './App.css';
import Home from './screens/Home';
import {Helmet} from "react-helmet";

function Banner() {
  return(
    <div id="Banner">
      <h3 className='Main-Text'>SOUS CHEF</h3>
    </div>
  )
}

function App() {
    return (
      <div className="App">
        <Helmet>
              <meta charSet="utf-8" />
              <title>SOUS CHEF</title>
              <link rel="canonical" href="https://sous-chef-web.netlify.app/" />
              <meta name="description" content="Recipe Recommendation Website" />
          </Helmet>
          <Banner />
        <header className="App-header">
          <Home />
        </header>
      </div>
    );
  }
  

export default App;


